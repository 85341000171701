import React from 'react';
import Container from 'react-bootstrap/Container';
import Section from 'components/common/Section';
import Ordonnance from 'images/Ordonnance.svg';
import Questionaire from 'images/Questionaire.svg';
import Consultation from 'images/consultation.svg';
import HIWLeftArrow from 'images/hiw-arrow-left-down.svg';
import Image from '../common/utils/Image';

const HowItWorksSection = (props) => (
  <Section className="hiw-section">
    <Container className="hiw-custom-container">
      <h2 className="h2-title text-lg-center">COMMENT ÇA MARCHE ?</h2>
      <div className="d-flex flex-column flex-lg-row align-items-center hiw-step-wrapper">
        <div className="d-flex flex-column justify-content-center align-items-center hiw-card">
          <Questionaire width={50} height={60} className="hiw-step-img hiw-question-img" />
          <span className="step-title">
            1. Remplissez
            <br />
            le questionnaire
          </span>
          <span>-</span>
          <span className="hiw-desc-span">
            100% confidentiel
            {' '}
            <br className="d-lg-none" />
            et
            <br className="d-none d-lg-inline" />
            {' '}
            à destination de la sexologue
            <br className="d-none d-lg-inline" />
            {' '}
            pour comprendre vos symptômes et
            <br className="d-none d-lg-inline" />
            {' '}
            vos objectifs.
          </span>
        </div>
        <div className="arrow-div d-none d-lg-inline">
          <HIWLeftArrow className="first-arrow" />
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center hiw-card">
          <Consultation width={120} height={60} className="hiw-step-img hiw-consult-img" />
          <span className="step-title">
            2. Réalisez votre/vos
            <br />
            rendez-vous en ligne (≃ 30 min)
          </span>
          <span>-</span>
          <span className="hiw-desc-span">
            Avec votre sexologue, vous
            {' '}
            <br className="d-lg-inline" />
            discuterez d’un accompagnement personnalisé.
          </span>
        </div>
        <div className="arrow-div d-none d-lg-inline">
          <HIWLeftArrow className="second-arrow" />
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center hiw-card">
          <Ordonnance height={60} width={51} className="hiw-step-img hiw-ordo-img" />
          <span className="step-title">
            3. Bénéficiez d'un
            <br />
            suivi intégral de A à Z
          </span>
          <span>-</span>
          <span className="hiw-desc-span">
            Recevez un traitement si
            <br className="d-lg-none" />
            {' '}
            besoin, bénéficiez du suivi de votre sexologue
            <br className="" />
            {' '}
            ainsi que d’une hotline
            <br className="d-none d-lg-inline" />
            {' '}
            5 jours sur&nbsp;7.
          </span>
        </div>
      </div>
      <HIWLeftArrow className="hiw-left-arrow d-lg-none" width={125} />
      <HIWLeftArrow className="hiw-right-arrow d-lg-none" width={125} />
    </Container>
  </Section>
);

export default HowItWorksSection;
