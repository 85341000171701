import { Button } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Flower from '../../../images/mia-flower.svg';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Section from 'components/common/Section';
import StyledLink from '../utils/StyledLink';
import Target from '../../../images/target.svg';
import Testimony from '../../Landing/Testimony';

const TestimoniesSection = ({ testimonies, path }) => (
  <Section className="background-color-pale-yellow testimony-section ">
    <div className="d-none d-lg-flex flex-column w-100">
      <h2 className="d-flex text-center section-subtitle h2-title justify-content-center">
        ELLES TÉMOIGNENT
      </h2>
      <div className="testimony-book-div">
        <div className="first-book">
          <div className="testimony-svg-div">
            <Flower className="testimony-desktop-flower" width={36} height={36} />
          </div>
          <div className="left-page">
            <span>
              {testimonies[0].testimony}
            </span>
            <span className="name-age">
              {testimonies[0].patient}
              , {testimonies[0].age}
            </span>
          </div>
          <div className="right-page">
            <span>
              {testimonies[1].testimony}
            </span>
            <span className="name-age">
              {testimonies[1].patient}
              , {testimonies[1].age}
            </span>
          </div>
          <div className="border-div" />
          <div className="border-div" />
          <div className="border-div" />
        </div>
        <div className="second-book">
          <div className="testimony-svg-div">
            <Target className="testimony-desktop-target" width={44} height={56} />
          </div>
          <div className="left-page">
            <span>
              {testimonies[2].testimony}
            </span>
            <span className="name-age">
              {testimonies[2].patient}
              , {testimonies[2].age}
            </span>
          </div>
          <div className="right-page">
            <span>
              {testimonies[3].testimony}
            </span>
            <span className="name-age">
              {testimonies[3].patient}
              , {testimonies[3].age}
            </span>
          </div>
          <div className="border-div" />
          <div className="border-div" />
          <div className="border-div" />
        </div>
      </div>
      <div className="d-flex justify-content-center cta-testimony-desktop">
        <StyledLink
          variant="orange"
          className=""
          path={path}
          margin
        >
          CONSULTER
        </StyledLink>
      </div>
    </div>
    <div className="testimony-div d-lg-none">
      <h2 className="text-center section-subtitle h2-title">
        ELLES TÉMOIGNENT
      </h2>
      <Row className="mb-lg-5 mx-0 d-flex align-items-center justify-content-center">
        <div className="d-flex carousel-testimony">
          {testimonies.map((data) => (
            <Col
              xs={{ offset: 1, span: 9 }}
              sm={{ offset: 1, span: 6 }}
              md={{ offset: 0, span: 5 }}
              lg={{ offset: 0, span: 4 }}
              className="mt-4 carousel-testimony-col"
              key={data.city}
            >
              <Testimony data={data} />
            </Col>
          ))}
        </div>
        <StyledLink
          variant="orange"
          path={path}
          className="btn-md mb-4"
        >
          CONSULTER
        </StyledLink>
      </Row>
    </div>
  </Section>
);

export default TestimoniesSection;
