import React from 'react';
import cx from 'classnames';
import Flower from '../../../images/mia-flower.svg';
import Target from '../../../images/target.svg';

import './styles.sass';

class Testimony extends React.Component {
  render() {
    const { data } = this.props;
    const {
      testimony,
      patient,
      age,
      flower,
      target,
    } = data;
    return (
      <div className="landing-testimony text-left p-3 lc-white">
        <div className="testimony-flower-target-div">
          <Flower className={cx('testimony-flower', { 'd-none': !flower })} />
          <Target className={cx('testimony-target', { 'd-none': !target })} />
        </div>
        <span className={cx('landing-testimony-text',
          { 'testimony-flower-margin-top-text': flower },
          { 'testimony-target-margin-top-text': target })}
        >
          {testimony}
        </span>
        <span className={cx('landing-testimony-patient', { 'testimony-margin-bottom-text': target })}>
          {patient}
          ,
          {age}
        </span>
      </div>
    );
  }
}

export default Testimony;
